.smloader,
.smloader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.smloader {
  margin: 0 auto;
  top: 40%;
  left: 47%;
  font-size: 10px;
  position: fixed;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#smloadingDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #8888888f;
  z-index: 99999;
}

/* inner table loader */
.loader {
  background-color: #83bbbb75;
  height: 50vh;
  width: 100%;
  vertical-align: middle;
  text-align: center;
  padding: 1em;
  margin: 0 auto;
  display: inline-block;
}
.loader img {
  position: absolute;
  top: 20%;
  left: 44%;
}
.navbar-mobile {
  display: none;
}
/*Tab Design*/
.tabs {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  font-weight: 300;
  margin-top: 5px;
}
/* Nav */
.tabs nav {
  text-align: center;
}

.tabs nav ul {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  font-size: 1.25em;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  -ms-box-pack: center;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
}

.tabs nav ul li {
  position: relative;
  z-index: 1;
  display: block;
  margin: 0;
  text-align: center;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  cursor: pointer;
}

.tabs nav .main {
  position: relative;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 2.5;
}

.tabs nav .main span {
  vertical-align: middle;
  font-size: 0.75em;
}

.tabs nav li.tab-current .main {
  color: #74777b;
}

.tabs nav .main:focus {
  outline: none;
}
.tabs-style-linebox nav ul li {
  margin: 0px 20px 0px 0px;
  -webkit-flex: none;
  flex: none;
}

.tabs-style-linebox nav .main {
  padding: 0 0 0 1.5em;
  color: #74777b;
  font-weight: 700;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  border-radius: 0 7px 0 7px;
  margin-bottom: 10px;
}

.tabs-style-linebox nav .main:hover,
.tabs-style-linebox nav .main:focus {
  color: #253772;
}

.tabs-style-linebox nav li.tab-current .main {
  color: #fff;
}

.tabs-style-linebox nav .main::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  /* background: #d2d8d6; */
  content: "";
  -webkit-transition: background-color 0.3s, -webkit-transform 0.3s;
  transition: background-color 0.3s, transform 0.3s;
  -webkit-transition-timing-function: ease, cubic-bezier(0.7, 0, 0.3, 1);
  transition-timing-function: ease, cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transform: translate3d(0, 100%, 0) translate3d(0, -3px, 0);
  transform: translate3d(0, 100%, 0) translate3d(0, -3px, 0);
}

.tabs-style-linebox nav li.tab-current .main::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.tabs-style-linebox nav .main:hover::after,
.tabs-style-linebox nav .main:focus::after,
.tabs-style-linebox nav li.tab-current .main::after {
  background: #253772;
}

@media screen and (max-width: 58em) {
  .tabs-style-linebox nav ul {
    display: block;
    box-shadow: none;
  }
  .tabs-style-linebox nav ul li {
    display: block;
    -webkit-flex: none;
    flex: none;
  }
}

.w-33 {
  width: 33%;
}
.w-70 {
  width: 70%;
}
.company-info {
  text-align: center;
  position: relative;
}
.company-info:before {
  content: "";
  width: 33%;
  position: absolute;
  left: 0;
  top: 13px;
  border: 1px solid #e4e4e4;
}
.company-info:after {
  content: "";
  width: 33%;
  position: absolute;
  right: 0;
  top: 13px;
  border: 1px solid #e4e4e4;
}
.cp {
  cursor: pointer;
}

.min-h-40 {
  min-height: 40px;
}
.min-h-60 {
  min-height: 60px;
}
.bg-grey {
  background-color: #f8f8f8;
}
.auth-card {
  min-height: 60vh;
  max-width: 490px;
  margin: 0 auto;
  box-shadow: 0px 33px 66px #00000029;
  border-radius: 12px;
}

/* video player */
.videoContainer {
  height: 86vh;
  background: transparent;
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.videoContainer .overlay {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  z-index: 999;
}
.videoContainer .overlay .bigPlayButton {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  /* background: url("assets/images/pause-play-button.svg"); */
  background-size: 100% 100%;
  transform: translate(-50%, calc(-50% - 30px));
  cursor: pointer;
  transition: width 0.2s, height 0.2s;
}
.videoContainer .overlay .bigPlayButton:hover {
  width: 90px;
  height: 90px;
}

.videoContainer .video {
  width: 100%;
  height: calc(100% - 40px);
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}
.videoContainer .video video {
  width: 100%;
  height: 100%;
  position: absolute;
}
.videoContainer .controls {
  width: 100%;
  height: 40px;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2147483647;
}
.videoContainer .controls .controlButton {
  width: 55px;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s;
  border: none;
  font-size: 30px;
  color: #fff;
}

.videoContainer .controls .controlButton:hover {
  background: transparent;
  color: #fff !important;
}
.videoContainer .controls .playButton > span {
  font-size: 40px;
}
.videoContainer .controls .playButton > span:hover {
  color: #fff !important;
}

.speedButton {
  font-size: 21px;
}
ul.speedList {
  position: absolute;
  right: 80px;
  bottom: 40px;
  background-color: #eef3f7;
  border-radius: 5px;
  list-style: none;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  padding: 0px;
  margin: 0px;
}
ul.speedList li {
  text-align: center;
  font-family: "verdana", tahoma, serif;
  font-size: 13px;
  padding: 5px 20px;
  cursor: pointer;
  display: block;
  border-bottom: 1px solid #ccc;
}
ul.speedList li:last-child {
  border-bottom: none;
}
ul.speedList li.selected {
  background-color: #71829e;
}
ul.speedList li.selected:first-child {
  border-radius: 5px 5px 0px 0px;
}
ul.speedList li.selected:last-child {
  border-radius: 0px 0px 5px 5px;
}

ul.captionsList {
  position: absolute;
  right: 135px;
  bottom: 40px;
  background-color: #eef3f7;
  border-radius: 5px;
  list-style: none;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  padding: 0px;
  margin: 0px;
}
ul.captionsList li {
  text-align: center;
  font-family: "verdana", tahoma, serif;
  font-size: 13px;
  padding: 5px 20px;
  cursor: pointer;
  display: block;
  border-bottom: 1px solid #ccc;
}
ul.captionsList li:last-child {
  border-bottom: none;
}
ul.captionsList li.selected {
  background-color: #71829e;
}
ul.captionsList li.selected:first-child {
  border-radius: 5px 5px 0px 0px;
}
ul.captionsList li.selected:last-child {
  border-radius: 0px 0px 5px 5px;
}

.videoContainer .controls .progressContainer {
  color: #eef3f7;
  width: calc(100% - 100px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.videoContainer .controls .progressContainer .progressBar {
  width: 100%;
  height: 8px;
  background: #d0d8e6;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #d0d8e6;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 10px;
  background: #a3b3ce;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 10px;
  background: #a3b3ce;
  cursor: pointer;
}
.videoContainer .controls .progressContainer .progressBar:hover + .time {
  opacity: 1;
  transform: translateY(0);
}
.videoContainer .controls .progressContainer .progressBar .progress {
  width: 10%;
  height: 100%;
  background: #a3b3ce;
  border-radius: 20px;
}
.videoContainer .controls .progressContainer .timer {
  margin: 0 10px;
  font-family: Arial, sans-serif;
  font-weight: 300;
  font-size: 13px;
  font-weight: 300;
  color: #fff;
  letter-spacing: 1px;
}
.videoContainer .controls .progressContainer .time {
  width: 80px;
  height: 25px;
  background: #71829e;
  position: absolute;
  top: -20px;
  left: 0;
  border-radius: 5px;
  color: #fff;
  font-family: Arial, sans-serif;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  letter-spacing: 1px;
  opacity: 0;
  transform: translateY(10px);
  transition: transform 0.3s, opacity 0.3s;
}
.videoContainer .controls .progressContainer .time::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 25px;
  left: 33px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #71829e;
}
.videoContainer .controls .volume {
  width: 130px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.videoContainer .controls .volume .intensityBar {
  width: 100px;
  height: 4px;
  background: #39a072;
  border-radius: 20px;
  max-width: 100px;
  overflow: hidden;
  transform-origin: right center;
  cursor: pointer;
  transition: all 0.5s;
}
.videoContainer .controls .volume .intensityBar {
  width: 100px;
  height: 4px;
  background: #d0d8e6;
  border-radius: 20px;
  max-width: 100px;
  overflow: hidden;
  transform-origin: right center;
  cursor: pointer;
  transition: all 0.5s;
}
.videoContainer .controls .volume .intensityBar .intensity {
  width: 50%;
  height: 100%;
  background: #a3b3ce;
}

.group-1305-XRrZEo {
  background-color: transparent;
  height: 45px;
  left: 45%;
  position: absolute;
  top: 20%;
  width: 45px;
}

.group-1306-mV4GEW {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.ellipse-18-Ixa1nE {
  -webkit-backdrop-filter: blur(3px) brightness(115%);
  backdrop-filter: blur(3px) brightness(115%);
  background-color: #ffffff4c;
  border-radius: 22.5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.polygon-1-Ixa1nE {
  background-color: transparent;
  height: 21px;
  object-fit: cover;
  position: absolute;
  right: calc(43.75% - 6px);
  top: calc(50% - 10px);
  width: 13px;
}

.profile-nav {
  background-image: url("./assets/images/bg-dark-green.png");
  height: 150px;
  position: relative;
}
.profile-nav .nav-tabs-custom {
  position: absolute;
  bottom: 0;
  font-weight: bold;
  font-size: 18px;
}
.profile-nav .nav-tabs-custom .nav-item a {
  color: #fff;
}

.videoplayer-modal {
  min-width: 98%;
}
.modal {
  background: #032529f2;
}
.sample-csv {
  background-color: #f3b9011a;
  border-color: #808080;
  border-style: dashed;
  text-align: center;
  padding: 15px;
  font-size: 16px;
  color: #f3b901;
  cursor: pointer;
  color: #000fff;
  text-decoration: underline;
}
.sample-csv i {
  font-size: 18px;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  position: relative;
  background: inherit;
}

.truncate::after {
  content: "";
  width: 0;
  height: 2ch;
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
  background: inherit;
  -webkit-mask-image: linear-gradient(to left, black, transparent);
  mask-image: linear-gradient(to left, black, transparent);
  transition: 0.2s;
}
.enterprise-logo-div {
  max-height: 220px;
}

.enterprise-logo {
  height: 100%;
  width: 75%;
}

.videoplayer-modal .modal-content {
  background: transparent;
  border: none;
}
.videoplayer-modal .modal-header {
  padding: 0;
  border-bottom: none;
}
.videoplayer-modal .video-title {
  color: #fff;
  font-size: 26px;
  font-weight: bolder;
  line-height: 10px;
}
.videoplayer-modal .video-coach-name {
  color: #c1c1c1;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
}
.videoplayer-modal .video-category-name {
  color: #c1c1c1;
  font-size: 14px;
  font-weight: 500;
  display: block;
}
.videoplayer-modal .close span {
  font-size: 55px;
  color: #fff;
}
.task-content {
  border-top: 1px solid #e4e4e4;
}
.task-content .para {
  padding: 15px 0;
  line-height: 24px;
  letter-spacing: 0px;
  color: #032529bf;
  text-align: left;
  margin-bottom: 0px;
}
.task-content .para:not(:first-child) {
  border-top: 1px solid #e4e4e4;
}

.content_main {
  margin-top: 70px;
  margin-bottom: 0px;
}
.contentLibraryThumb {
  max-height: 200px;
  min-height: 200px;
  object-fit: cover;
}
.text-primary-dark {
  color: #032529bf;
}
.mini-widget-cards {
  border-radius: 10px;
  color: #fff;
  max-height: 155px;
  height: 155px;
}
.mini-widget-cards h4,
.mini-widget-cards i {
  font-size: 38px;
  color: #fff;
}
.mini-widget-cards .card-body {
  padding: 0.65rem;
}
.mini-widget-cards .iconImg {
  padding: 12px;
}
.journaling-main .row {
  height: 100%;
  align-items: flex-start;
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  overflow-y: auto;
}
.journaling-question {
  padding-top: 80px;
}
.journaling-video-question {
  padding-top: 50px;
}
.journaling-question h6 {
  font-weight: 600;
  font-size: 14px;
}
.journaling-question h1 {
  font-weight: 900;
  font-size: 26px;
  width: 100%;
  max-width: 70%;
}
.journaling-video-question h1 {
  font-weight: 700;
  font-size: 22px;
  width: 100%;
  max-width: 70%;
}
.journaling-question select,
.journaling-question textarea,
.journaling-video-question textarea {
  width: 100%;
  max-width: 70%;
  resize: none;
}
.jounaling-img {
  max-width: 100%;
  height: auto;
}
.jounaling-video-img {
  max-width: 100%;
  height: auto;
  width: 60%;
}
.journaling-main {
  max-height: 80vh;
  height: 100vh;
}
.jounaling-modal-dialog {
  width: 100% !important;
  max-width: 90% !important;
}
.jounaling-img-main {
  text-align: center;
}
@media (max-width: 991px) {
  .journaling-main {
    max-height: max-content !important;
    height: auto;
    padding-bottom: 30px !important;
  }
  .jounaling-img {
    max-width: 60%;
  }
  .journaling-question {
    padding-top: 0px;
  }
  .journaling-question h6 {
    font-size: 12px;
  }
  .journaling-question h1 {
    font-size: 18px;
  }
  .journaling-video-question h1 {
    font-size: 14px;
  }
}

.custom-file-label,
.custom-file-input {
  z-index: 0;
}

/* audio player */
.rhap_progress-indicator,
.rhap_progress-filled,
.rhap_volume-indicator {
  background: #038389 !important;
}
.rhap_main-controls-button,
.rhap_volume-button {
  color: #038389 !important;
}
.react-tel-input .form-control {
  width: 100% !important;
}

/* tags */
.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.react-tagsinput--focused {
  border-color: #038389;
}

.react-tagsinput-tag {
  background-color: #038389;
  border-radius: 2px;
  border: 1px solid #038389;
  color: #fff;
  display: inline-block;
  font-family: "Muli";
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 2px 5px 2px 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  color: #fff;
}

.react-tagsinput-tag a::before {
  content: " ×";
}
.web_card {
  padding: 40px 60px;
  background: #ffffff;
}
.getmee_form_section {
  padding: 100px 60px;
  background: #032d31;
}
.getmee_form_title {
  text-align: center;
}

.getmee_form_title h2 {
  font-size: 46px;
  color: #c1c1c1;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 56px;
  margin-bottom: 40px;
}
.getmee_form_form {
  width: 70%;
  margin: 0 auto;
}
.getmee_ff_tab {
  margin-bottom: 25px;
}

.getmee_ff_tab input {
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #f2f2f2;
  width: 100%;
  height: 56px;
  padding: 10px 0px;
  font-size: 18px;
  color: #fff;
  outline: none;
}

.getmee_ff_btn {
  margin: 20px 0;
}

.getmee_ff_btn button {
  background: #038389;
  width: 100%;
  border-radius: 100px;
  font-size: 18px;
  color: #fff;
  padding: 12px 0;
}
.getmee_ff_btn button:hover,
.getmee_ff_btn button:focus {
  color: #fff;
}

.getmee_ff_info {
  width: 70%;
  margin: 0 auto;
}

.getmee_ff_info p {
  font-family: "Graphik Regular";
  font-size: 16px;
  text-align: center;
  color: #c1c1c1bf;
}

.getmee_form_form {
  width: 70%;
  margin: 0 auto;
}
.popup_left {
  padding: 50px;
  display: inline-grid;
  height: 100%;
}

.popup_right {
  padding: 50px;
  box-shadow: 0px 3px 10px #00000029;
}

.popup_tab {
  margin-bottom: 20px;
}

.popup_tab label {
  font-size: 14px;
  display: block;
  margin: 0;
  color: #727272;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.popup_tab input {
  width: 100%;
  height: 40px;
  box-shadow: 0px 3px 3px #0000001a;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px 15px;
}

.popup_tab textarea {
  width: 100%;
  resize: none;
  box-shadow: 0px 3px 3px #0000001a;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px 15px;
}

.popup_tab_checkbox {
  margin-bottom: 20px;
}

.popup_tab_checkbox label {
  font-size: 14px;
  margin: 0;
  color: #727272;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding-left: 14px;
}

.popup_card_price {
}

.popup_card_price label {
  font-size: 16px;
  color: #9a9a9a;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.popup_card_price p {
  font-size: 42px;
  color: #032529;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin: 0;
}

.popup_card_details {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.pc_details_img {
  width: 40%;
  margin-right: 4%;
}
.pc_details_des {
  width: 60%;
  font-family: "Poppins", sans-serif;
}
.pc_details_des h3 {
  font-size: 18px;
  color: #032529;
  font-weight: 700;
}
.pc_details_des p {
  font-size: 16px;
  color: #888888;
  font-weight: 400;
}
.popup_info {
  display: flex;
  align-items: flex-end;
}
.popup_info p {
  margin: 0;
}
.popup_info a {
  margin: 0 4px;
}
img.stripe {
  height: 20px;
}
.enroll_now .modal-body {
  padding: 0;
}

.popup_tab_btn .btn-primary {
  background: #038389 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 12px #00000029;
  border-radius: 34px;
  font-size: 18px;
  color: #fff;
  font-family: "Graphik Medium";
}

.popup_card_logo {
  display: flex;
  margin-bottom: 30px;
}

.popup_card_logo img {
  margin-right: 20px;
}

.popup_middle {
  padding: 50px;
}

.popup_result {
  text-align: center;
  margin: 30px auto;
  width: 60%;
}

.popup_result h4 {
  font-size: 30px;
  color: #032529;
  line-height: 40px;
  margin: 30px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.btn_ok {
  background: #038389 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 12px #00000029;
  border-radius: 34px;
  font-size: 18px;
  color: #fff;
  font-family: "Graphik Medium";
  display: inline-block;
  padding: 12px 50px;
  text-transform: capitalize;
}
.card_list_filters {
  display: flex;
  justify-content: space-between;
}

.card_list {
  width: 90%;
}

.card_list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  border-bottom: 1px solid #bcbcbc;
}

.card_list ul li {
  margin: 0;
}

.card_list ul li a {
  display: block;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #9a9a9a;
  padding: 12px 50px;
}
.card_list ul li a.active,
.card_list ul li a:hover,
.card_list ul li a:focus {
  color: #000;
  border-bottom: 2px solid #032529;
}
.card_filters {
  width: 15%;
}

.card_filters .filter_btn {
  border: 1px solid #e4e4e4;
  display: block;
  background: #f8f8f8;
  width: 100%;
  text-align: center;
  padding: 12px 30px;
  border-radius: 4px;
  font-size: 18px;
  color: #032529;
  font-weight: 600;
}

.card_filters .filter_btn img.filter {
  margin-right: 10px;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
}
.btn-edu-primary {
  background-color: #e46f2e;
  color: #fff;
  border: 1px solid #e46f2e;
  border-radius: 50px;
  padding: 12px 30px;
  font-size: 14px;
  font-family: "Graphik Semibold";
}
.btn-edu-primary:hover {
  color: #fff;
}
.btn-edu-default {
  background-color: #032529;
  color: #fff;
  border: 1px solid #010c0d;
  border-radius: 21px;
}
.btn-edu-default:hover {
  color: #fff;
}
.edu-banner-row {
  background: #038389 0% 0% no-repeat padding-box;
  height: 620px;
}
.edu-banner-left {
  padding: 0 130px;
}
.edu-banner-title {
  text-align: left;
  color: #ffffff;
  font-size: 46px;
  font-family: "Poppins", sans-serif;
  margin: 25px 0;
  line-height: 56px;
}
.edu-banner-btn {
  margin-top: 0;
  box-shadow: 0px 3px 3px #00000029;
  padding: 12px 35px;
  border-radius: 60px;
  font-size: 20px;
  font-family: "Graphik Semibold";
}
.edu-banner-img {
  width: 535px;
  height: 551px;
  object-fit: cover;
  margin-right: 15%;
  float: right;
  transform: matrix(0.99, -0.1, 0.1, 0.99, 0, 30);
  text-align: right;
  box-shadow: 0px 3px 6px #00000029;
}

.course-card-title {
  font-family: "Graphik Semibold";
  color: #032529;
}
.course-card-providername {
  font-family: "Graphik Regular";
  color: #03252980;
}
.rating {
  display: flex;
  align-items: center;
}
.rating .norating {
  font-family: "Graphik Regular";
  font-size: 12px;
  color: #03252966;
}
span.rating_star {
  padding: 0px 8px;
  margin-top: -3px;
}
span.rating_count {
  color: #888888;
}
span.rating_number {
  color: #eaa527;
  font-weight: 600;
}
span.filter_rating_no {
  padding-top: 5px;
}
.filter_category .rating {
  cursor: pointer;
}
.course-main-price {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #032529;
}
.course-stripe-price {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #888888;
}
/***/

.filter_section {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.filter_overlay {
  background: rgb(0 0 0 / 60%);
  width: 100%;
  height: inherit;
}

.filter_site {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  width: 26%;
  display: grid;
  align-content: space-between;
}

img.closeicon {
  float: right;
  margin-top: 12px;
  cursor: pointer;
}

.filter_title {
  border-bottom: 1px solid #c1c1c1;
  padding: 10px 25px;
}

.filter_title h2 {
  font-size: 30px;
  color: #032529;
  font-weight: 600;
}

.filter_title p {
  font-size: 18px;
  color: #032529;
  margin: 0;
}

img.closeicon {
  float: right;
  margin-top: 12px;
}

.filter_category {
  border-bottom: 1px solid #c1c1c1;
  padding: 25px 25px;
}

.filter_categorys {
  overflow-y: scroll;
  height: 80vh;
}
.filter_categorys::-webkit-scrollbar {
  width: 8px;
}
.filter_categorys::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0;
}
.filter_categorys::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 0;
}

.filter_bottom {
  display: flex;
  align-items: center;
  padding: 10px 25px;
  justify-content: space-around;
  border-top: 1px solid #c1c1c1;
}

.filter_bottom a.fb_reset {
  font-size: 16px;
  color: #e46f2e;
  line-height: 28px;
  background: #fff;
  padding: 10px 40px;
  text-transform: uppercase;
  border-radius: 50px;
  border: 1px solid #e46f2e;
  font-weight: 600;
}

.filter_bottom a.fb_apply {
  font-size: 16px;
  color: #fff;
  line-height: 28px;
  background: #e46f2e;
  padding: 10px 40px;
  text-transform: uppercase;
  border-radius: 50px;
  font-weight: 600;
}

.fc_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.fc_title h4 {
  font-size: 22px;
  color: #032529;
  font-weight: 600;
}

.fc_tab {
}

.fc_tab ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.fc_tab ul li {
  margin: 8px 0;
}

.fc_tab ul li input[type="checkbox"] {
  margin-right: 10px;
  vertical-align: middle;
  margin-top: 4px;
}

.fc_tab ul li label.text {
  font-size: 16px;
  vertical-align: middle;
  font-weight: 400;
  margin: 0;
}

.course-detail {
  width: 100%;
}
.color1 {
  background: #f57e3a;
}

.course_list {
  margin-bottom: 30px;
}
.course_list ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  border-bottom: 1px solid #bcbcbc;
}
.course_list ul li {
  margin: 0;
}
.course_list ul li a {
  display: block;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #9a9a9a;
  padding: 12px 50px;
}
.course_list ul li a.active,
.course_list ul li a:hover,
.course_list ul li a:focus {
  color: #000;
  border-bottom: 2px solid #032529;
}

.course-description {
  padding: 25px;
  background: #f8f8f8;
  margin-bottom: 25px;
}

.course-description h3 {
  font-size: 26px;
  margin-bottom: 20px;
  color: #405a5d;
}

.course-description p {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
}

.course-detail {
  padding: 3rem;
}

.course-detail-left {
  padding: 0 60px;
}

.course-detail-img {
  text-align: center;
}

.course-detail-title {
  font-size: 40px;
  line-height: 50px;
  color: #032529;
  margin: 0 0 20px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.course-detail-title h5 {
  font-size: 20px;
}

.course-detail-left h5 {
  font-size: 20px;
  color: #032529;
  margin: 15px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.course-detail-left p {
  font-size: 16px;
  line-height: 30px;
  color: #032529;
  margin: 15px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.course-detail-btn {
  background-color: #032529;
  color: #fff;
  border: 1px solid #010c0d;
  margin-top: 20px;
  box-shadow: 0px 3px 3px #00000029;
  padding: 12px 35px;
  border-radius: 80px !important;
  font-size: 20px;
  font-family: "Graphik Semibold";
}
.course-detail-btn:hover {
  background-color: #032529;
  color: #fff;
  border: 1px solid #010c0d;
}
.course-detail-btn .smalltext {
  display: block;
  font-size: 16px;
  font-family: "Graphik Regular";
}
.course-detail-img img {
  width: 100%;
  max-width: 100%;
}

.how_it_works {
}

.how_it_works ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.how_it_works ul li {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  padding: 0 20px;
}

img.arrowright {
  position: absolute;
  top: 20px;
  right: auto;
  left: auto;
}

.how_it_works {
  margin: 30px 0;
}

.how_it_works span.number {
  background: #038389 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 5px solid #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  font-size: 24px;
  color: #fff;
  padding: 5px 0;
  display: block;
  margin: 0 auto;
}

.how_it_works label.text {
  margin: 15px 0 0 0;
  font-size: 18px;
  color: #032529;
}

.user_profile {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.user_profile_img {
  width: 110px;
  height: 110px;
  margin-right: 20px;
  border-radius: 100%;
  padding: 10px;
  background: #fff;
  overflow: hidden;
}

.user_profile img.profile {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
  object-fit: contain;
}

.user_profile_text h4 {
  font-size: 22px;
}

.user_profile_text p {
  font-weight: 400;
  font-family: "Muli", sans-serif;
}

.syllabus_box {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cecece;
  padding: 10px 0;
  align-items: center;
}

.syllabus_box_head h4 {
  margin: 0;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #405a5d;
}
.holiday_study {
  padding: 80px 0;
  background: #032d31;
}

.holiday_study h3 {
  font-size: 28px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin-bottom: 15px;
}

.holiday_study p {
  font-size: 16px;
  color: #fff;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

button.holiday_study_btn {
  background: #038389 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 3px #00000029;
  border: 1px solid #010c0d;
  font-size: 22px;
  color: #fff;
  border-radius: 100px;
  padding: 12px 35px;
  float: right;
}

button.holiday_study_btn span.smalltext {
  display: block;
  font-size: 16px;
  font-family: "Graphik Regular";
}
.published_tag {
  position: absolute;
  left: 10px;
  top: 10px;
  background: #e46f2e;
  font-size: 12px;
  color: #fff;
  font-family: "Graphik Medium";
  padding: 4px 10px;
  border-radius: 4px;
}
/***/

.web_topbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background: #032529;
  display: none;
}

.topbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background: #032529;
}

.topbar {
  padding: 22px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbar h3 {
  margin: 0;
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
}

.topbar a.topbar_btn {
  border: 1px solid #038389;
  font-size: 14px;
  color: #038389;
  padding: 10px 35px;
  border-radius: 4px;
  font-weight: 600;
}

.graysection {
  padding: 70px 60px;
  background: #f8f8f8;
}

.graysection_title {
  text-align: center;
  width: 56%;
  margin: 0 auto 20px auto;
}

.graysection_title h2 {
  margin: 0;
  font-size: 46px;
  color: #000000;
  line-height: 56px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.getmee_card {
  background: #e46f2e;
  position: relative;
  min-height: 440px;
}

.getmee_card_img {
  width: 100%;
  position: relative;
  /* height: 320px; */
  height: 250px;
}

.getmee_card_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.getmee_card_logo {
  width: 112px;
  height: 112px;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  right: 20px;
  margin-top: -80px;
}

.getmee_card_logo img {
  width: 100%;
  height: 100%;
  padding: 10px;
  object-fit: contain;
}

.getmee_card_text {
  padding: 40px 40px;
}

.getmee_card_text h4 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 15px;
}

.getmee_card_text p {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
}

.seemore {
  background: #efefef;
  width: 100%;
  display: grid;
  height: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.seemore h3 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #038389;
}

.seemore p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #038389;
}

.breadcrumbs {
  margin-left: 60px;
}

.breadcrumbs-col {
  margin-bottom: 50px;
}

.breadcrumbs ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.breadcrumbs ul li {
  font-size: 14px;
  color: #efefef;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.breadcrumbs ul li a {
  color: #fff;
}

.smallarrowright {
  margin: 0 10px;
}

.cd-price {
  font-size: 26px;
  color: #032529;
  margin: 15px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.cd-price span.linethrough {
  color: #fff;
  font-weight: 400;
  text-decoration: line-through;
  margin-left: 15px;
}
.insititution.breadcrumbs ul li {
  color: #03252980;
}

.insititution.breadcrumbs ul li a {
  color: #032529bf;
}

.web_review {
  margin-top: 60px;
}
.web_review .swiper-horizontal {
  padding: 10px;
}
.review_title {
  margin-bottom: 30px;
}

.review_title h2 {
  margin: 0;
  font-size: 26px;
  line-height: 36px;
  display: flex;
  align-items: center;
}

.review_title span.yellow {
  color: #eaa527;
  margin-right: 10px;
}

.review_title span.green {
  color: #032529;
  margin: 0 10px;
  font-weight: 800;
}

.review_title span.smalltext {
  color: #032529;
  font-size: 18px;
  font-weight: 400;
}

.review_title span.style-module_starRatingWrap__q-lJC {
  margin-top: -12px;
}

.review_title span.style-module_starRatingWrap__q-lJC {
  margin-top: -12px;
}

.review_tab {
  box-shadow: 0px 3px 6px #00000029;
  padding: 30px 25px;
  display: flex;
  align-items: flex-start;
  min-height: 260px;
}

.review_tab_img {
  margin-right: 15px;
  width: 17%;
}

.review_tab_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.review_tab_text {
  width: 80%;
}

.review_tab_text h4 {
  font-size: 22px;
  color: #032529;
  font-weight: 800;
  margin: 0;
}

.review_tab_text p {
  margin: 0;
  font-size: 16px;
  color: #032529;
}

.review_tab_text span.style-module_starRatingWrap__q-lJC {
  margin: 10px 0;
}

.review_button {
  text-align: center;
  margin-top: 15px;
}

.review_button a {
  font-size: 16px;
  color: #032529;
  font-weight: bolder;
}

.youstudy-international {
  padding: 3rem;
}

.youstudy-logo {
  margin-left: 60px;
}

.youstudy-logo img.youstudy {
  height: 80px;
}

h2.youstudy-title {
  font-size: 40px;
  line-height: 50px;
  color: #032529;
  margin: 0 0 20px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.youstudy-international-left {
  margin: 0 60px;
}

.youstudy-international-left p {
  font-size: 16px;
  line-height: 30px;
  color: #032529;
  margin: 15px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.youstudy-international-right {
  text-align: center;
}

.courses_title {
  text-align: center;
  margin: 0 auto 20px auto;
}

.courses_title h2 {
  margin: 0;
  font-size: 32px;
  color: #032529;
  line-height: 42px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.swiper-button-next,
.swiper-button-prev {
  width: 42px !important;
  height: 42px !important;
  z-index: 10;
  background: #e3e3e3;
  border-radius: 100%;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 18px !important;
  color: #000000;
  font-weight: bolder;
}

/* search */
.gt-search-form-autocomplete {
  position: relative;
}

.gt-search-form-autocomplete .gt-search-form-autocomplete-input {
  border: 0;
  flex: 1;
  height: 35px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.gt-unstyled-list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: none;
}

.gt-search-form-autocomplete .gt-search-form-autocomplete-suggestions {
  background: #fff;
  border: 1px solid #d1d7dc;
  left: 0;
  padding: 1.6rem;
  margin-top: 0.4rem;
  position: absolute;
  right: 0;
  top: 100%;
  transform-origin: top;
  z-index: 1000;
  overflow: auto;
}

.gt-search-form-autocomplete
  .gt-search-form-autocomplete-suggestions-with-image {
  background: #fff;
  border: 1px solid #d1d7dc;
  left: 0;
  padding: 1.6rem;
  margin-top: 0.4rem;
  position: absolute;
  right: 0;
  top: 100%;
  transform-origin: top;
  z-index: 1000;
  overflow: auto;
  padding: 0;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.gt-search-form-autocomplete-title {
  display: flex;
  height: 3.2rem;
  padding: 1.2rem 1.2rem 0.8rem;
  align-items: center;
}

.gt-search-form-autocomplete-title-text,
.gt-search-form-autocomplete-title-action {
  width: 100%;
}

.gt-search-form-autocomplete-title-action-text {
  float: right;
  cursor: pointer;
}

.gt-search-form-autocomplete-input-group {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 2.4rem 0 0;
}

.gt-search-form-autocomplete-input-group-reversed {
  flex-direction: row-reverse;
}

.gt-search-form-autocomplete-input-group-reversed
  .gt-search-form-autocomplete-input {
  padding: 0 0 0 0.4rem;
}

.gt-search-form-autocomplete-suggestion-block-list-item {
  padding-left: 1.2rem;
  padding-right: 0.8rem;
}

.gt-search-form-autocomplete-suggestion-block-list-item:hover {
  background-color: #f7f9fa;
}

@media (max-width: 37.5em) {
  .gt-search-form-autocomplete-input-group {
    padding: 0;
  }

  .gt-search-form-autocomplete-suggestions,
  .gt-search-form-autocomplete-suggestions-with-image {
    height: calc(100vh + 40vh - 6.4rem);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .gt-search-form-autocomplete-suggestions,
  .gt-search-form-autocomplete-suggestions-with-image {
    animation: gt-search-form-autocomplete-expand 150ms
      cubic-bezier(0, 0, 0.38, 0.9);
  }

  .gt-search-form-autocomplete-suggestion {
    animation: gt-search-form-autocomplete-fade-in 150ms linear 150ms forwards;
    opacity: 0;
  }
}

.gt-search-form-autocomplete-group {
  display: flex;
  align-items: center;
}

.gt-search-form-autocomplete-group-search {
  height: 3.2rem;
}

.gt-search-form-autocomplete-group-icon {
  margin-top: 0;
  margin-left: 0.4rem;
  margin-right: 2rem;
  font-size: 18px;
}

.gt-search-form-autocomplete-group-course-img {
  margin-right: 1.6rem;
}

.gt-search-form-autocomplete-group-course-img img {
  border: 1px solid #d1d7dc;
  max-width: none;
}

.gt-search-form-autocomplete-group-img {
  margin-right: 1.6rem;
}

.gt-search-form-autocomplete-group-img img {
  max-width: none;
}

.gt-search-form-autocomplete-suggestion-details {
  display: flex;
  margin-top: 0.4rem;
  align-items: center;
  color: #6a6f73;
}

.gt-search-form-autocomplete-suggestion-instructor-name {
  padding-left: 0.8rem;
}

.gt-search-form-autocomplete-suggestion-focus {
  outline: 4px solid #b4690e;
}

.gt-search-form-autocomplete-suggestion-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02rem;
  font-size: 16px;
}

@keyframes gt-search-form-autocomplete-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gt-search-form-autocomplete-expand {
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}

.gt-heading-xs {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02rem;
  font-size: 12px;
}
.gt-text-xs {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.4;
  font-size: 12px;
}

/* search bar mobile */
.gt-searchbtn {
  margin-left: 5px;
  padding: 10px;
  font-size: 22px;
}
.gt-search-form-autocomplete-input-group-reversed {
  flex-direction: row-reverse;
}
.header--search-bar--2rQ3t {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 1011;
}

.header--search-bar--2rQ3t .header--search-bar-form--1IPCf {
  padding: 0.4rem 2.6rem 0.4rem 0.4rem;
  max-width: none;
  border-bottom: 1px solid #d1d7dc;
}

.header--search-bar--2rQ3t
  .header--search-bar-form--1IPCf
  .gt-search-form-autocomplete-suggestions {
  margin: 0;
}

.header--search-bar-close--1YT2F {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  z-index: 1;
}

.header--full-page-overlay--3kmF7 {
  background-color: #fff;
}

#header-toggle-search-bar[data-checked=""] ~ .header--search-bar-layer--V7PsO {
  display: none;
}

.header--header--33tMC .header--cart-badge--HiE5n {
  position: absolute;
  font-size: 1.2rem;
  margin: 0 0 0 -0.4rem;
  top: 0;
  left: 50%;
}

.header--mark--3yOX2 {
  position: absolute;
  width: 100%;
  left: 0;
}

.header--floating--1Jd-7 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
  animation: header--slide-from-top--1W4GA 250ms cubic-bezier(0, 0, 0.38, 0.9)
    0ms forwards;
}

.language-selector-overlay--container--1f-qQ {
  padding: 2.4rem;
  animation: language-selector-overlay--fade-in--1SQ6I 250ms linear;
}

@media (prefers-reduced-motion: reduce) {
  .language-selector-overlay--container--1f-qQ {
    animation: none;
  }
}

@keyframes language-selector-overlay--fade-in--1SQ6I {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.search-mobile .search-icon {
  font-size: 22px;
}
.search-mobile .gt-searchbtnclose {
  font-size: 22px;
  cursor: pointer;
  padding: 0 5px;
}
.group-dropdown {
  height: 30px;
  margin-top: 20px;
  width: 250px;
  border: 1px solid #eee;
  padding-left: 5px;
}
.group-dropdown:focus-visible {
  outline: none;
}
.expand-icon i {
  font-size: 1rem;
  cursor: pointer;
}
.expand-item {
  background-color: #e4e4e4;
}
.expand-blank {
  padding: 10px;
  border: 1px solid #eff2f7;
}
.expand-actions i {
  font-size: 1rem;
  cursor: pointer;
  padding: 2px;
}
.expand-table th,
.expand-table td {
  padding: 0.5rem;
}
.permission-modules .list-group-item {
  padding: 0.4rem 1rem;
}
.auth-code-container {
  padding: 16px;
}

.auth-code-input {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  color: #494949;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
}

.auth-code-input:focus {
  appearance: none;
  outline: 0;
  box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
}
.task-view-cards {
  transform: scale(0.95);
  transition: transform 330ms ease-in-out;
}
.task-view-cards-selected {
  transform: scale(1.05);
  transition: transform 330ms ease-in-out;
  box-shadow: -10px 10px #5c575752;
}
.checklist-icon {
  margin-top: 7px;
}

.add-group-btn {
  float: right;
  cursor: pointer;
  color: #025f64;
}
.refresh-icon {
  background-color: transparent;
  margin-left: 10px;
  cursor: pointer;
}
.hyperlink {
  text-decoration: underline;
  cursor: pointer;
  color: #0066cc;
}
.dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}
.eye-mc-ico {
  position: absolute;
  right: 5px;
  top: 5px;
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  z-index: 10;
}
.eye-mc-ico img {
  width: 30px;
  height: 30px;
}
.rhap_container {
  padding: 5px 10px !important;
}
.task-hr {
  margin-top: 5px;
  margin-bottom: 5px;
}
.dash-task-content {
  max-height: 550px;
  overflow-x: auto;
}
.company-info-inner-section {
  padding: 5px;
  margin-bottom: 5px;
}
.EditImage .popup {
  z-index: 9999 !important;
}
.dash-task-feedback-ul {
  max-height: 250px;
  overflow-x: auto;
}
.dash-task-feedback-ul li {
  padding: 5px;
}
.comp-info-icon-img {
  width: 200px;
  height: 200px;
  display: block;
  border: 1px solid #dedede;
  border-radius: 5px;
}
.comp-info-icon-main {
  position: relative;
  width: 200px;
  height: 200px;
}
.comp-info-icon-remove {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #ff3d60;
  font-size: 18px;
  cursor: pointer;
}
.comp-info-files-list {
  border-bottom: 1px solid #E4E4E4;
  font-size: 15px;
  padding-bottom: 4px;
}
.comp-info-files-list .ci-file-icon {
  cursor: pointer;
  margin-right: 5px;
  padding-left: 5px;
}
.comp-info-files-list .ci-file-name {
  color: #E46F2E;
  text-decoration: underline;
  cursor: pointer;
}
.comp-info-files-list .ci-file-action {
  cursor: pointer;
  color: #038389;
  float: right;
  padding-right: 5px;
}
.comp-info-files-list .ci-file-name a {
  color: #E46F2E;
}
.edit-language {
  cursor: pointer;
  color: #038389;
  font-size: 14px;
  float: right;
  text-decoration: underline;
}
.mb-80px {
  margin-bottom: 80px !important;
}
/* self registration page */
.section-p {
  text-align: left;
  color: #032529BF;
  font-size: 18px;
  font-weight: 500;
}
.survey-footer-section {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.inbox-attachment-message-list {
  display: flex;
  height: 60px;
}
.inbox-attachment-message-filetype {
  display: flex;
  align-items: center;
}
.inbox-attachment-message-filedata {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  font-size: 14px;
}
.sm-all-list, .scrollable-list {
  min-height: 70vh;
  max-height: 70vh;
  overflow-y: auto;
}
.sm-all-list .list-group-item {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sm-all-list .list-group-item.disabled {
  background-color: #f5f5f5;
}
.sm-all-list .list-group-item:hover {
  background-color: #e0f7fa;
}
.sm-all-list .list-group-item button.disabled {
  background-color: #eee;
  color: #74788d;
}
.sm-all-list .list-group-item button {
  height: 18px;
}
.fade-in {
  animation: fadeIn 0.5s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.clear-search-btn {
  position: absolute;
  right: 5px;
  top: 25%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #999;
}

.clear-search-btn:hover {
  color: #333;
}
.load-more {
  justify-content: space-around;
}
.load-more:hover {
  cursor: pointer;
  color: #038389;
}
.list-modal-counts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.list-modal-counts p {
  margin-bottom: 0px;
}
.folder-task-card {
  background-color: #FFF;
  padding: 10px;
  margin-right: 10px;
  width: 235px;
  margin-bottom: 10px;
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.folder-task-view {
  padding: 5px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  min-height: 150px;
  max-height: 150px;
}
.folder-view-task-type {
  display: flex;
  font-size: 12px;
  margin-bottom: 5px;
  justify-content: center;
}
.folder-view-task-name {
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-top: 10px;
}
.folder-options {
  cursor: pointer;
  padding-right: 10px;
  padding-left: 10px;
}
.gt-hyperlink {
  color: #028388;
  text-decoration: underline !important;
}
.gt-hyperlink:hover {
  color: #1a0dab !important;
}